
import { defineComponent } from 'vue';
import { LocationQueryValue } from 'vue-router';
import axios from "axios";
import ConfettiExplosion from "vue-confetti-explosion";

export default defineComponent({
  name: "Home",
  components: { ConfettiExplosion },
  data() {
    return {
      isPlaying: false,
      isVideo: true,
      isPicker: false,
      flowTwo: false,
      videoHeight: 400,
      videoWidth: 400,
      boxHeight: 100,
      bucketId: 'rtl_verjaardag_video',
      videoId: '' as string | LocationQueryValue[],
      shareData: {
        title: "Bekijk mijn verjaardagsvideo",
        text: "Bekijk mijn verjaardagsvideo",
        url: window.location.href,
      },
      video1: require('@/assets/videos/main.mp4'),
      videoA: require('@/assets/videos/optionA.mp4'),
      videoB: require('@/assets/videos/optionB.mp4'),
      pickerBg: require('@/assets/images/others/still.jpg'),
      firstname: ""
    }
  },
  created() {
    if (this.$route.query.groupId) {
      this.videoId = this.$route.query.groupId;
      //Set videos (1-2)
      this.getVideos();
    }
    if (this.$route.query.name) {
      this.firstname = this.$route.query.name as string;
    }
    this.bucketId = process.env.VUE_APP_ENV_TYPE === 'production' ? 'rtl_verjaardag_video' : 'rtl_test'
  },
  mounted(){
    this.onStart()
  },
  methods: {
    async getVideos(){
      try {
        const res = await axios.get(`${process.env.VUE_APP_API_URL}/api/videos/${this.videoId}`);
        if(res.data.data.videoMainId) this.video1 = `https://affectivity-storage.lwmicro.com/output/${this.bucketId}/${res.data.data.videoMainId}.mp4`
        if(res.data.data.videoAId) this.videoA =  `https://affectivity-storage.lwmicro.com/output/${this.bucketId}/${res.data.data.videoAId}.mp4`
        if(res.data.data.videoBId) this.videoB = `https://affectivity-storage.lwmicro.com/output/${this.bucketId}/${res.data.data.videoBId}.mp4`
        return res.data.data;
      } catch (err) {
        console.log("Request video Error:", err);
      }
    },
    onStart() {
      this.isPicker = true;
      this.isPicker = false;
      this.boxHeight = document.getElementById("video-box")?.offsetHeight as number;
      if(window.innerWidth >= 576) this.boxHeight += 40;
      else this.boxHeight += 16;
    },
    async onEnd () {
      this.videoHeight = document.getElementById("format")?.offsetHeight as number;
      this.videoWidth = document.getElementById("format")?.offsetWidth as number;
      if(this.flowTwo == false){
        this.isPicker = true;
        this.isVideo = false;
        this.flowTwo = true;
        await this.delayTime(2.1);
      }
    },
    async optionClick(option: string) {
      // Set second video source
      if (option === "1") this.video1 = this.videoA;
      else this.video1 = this.videoB;

      if(window.innerWidth <= 576){
        await this.delayTime(1)
      }
      //Swap view
      
      await this.delayTime(1)
      this.isVideo = true;
      this.isPicker = false;

      // Auto play second part of video
      setTimeout(function () {
        const video = document.getElementById('video') as HTMLVideoElement | null;
        video?.play();
      }, 500);
    },
    async handleShare(channel: string) {
      switch (channel) {
        case 'general':
          try {
            await navigator.share(this.shareData);
          } catch (err) {
            console.log(err);
          }
          break;
        case 'twitter':
          const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${this.shareData.text}`)}&url=${encodeURIComponent(`${this.shareData.url}`)}`;
          window.open(url, 'twitter', `width=550,height=450,scrollbars=yes,toolbar=no,location=yes`);
          break;
        case 'facebook':
          const fb = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareData.url)}`;
          window.open(fb, 'facebook', `width=550, height=450, scrollbars=yes, toolbar=no, location=yes`);
          break;
        case 'whatsapp':
          const waUrl = `https://wa.me/?text=${encodeURIComponent(`${this.shareData.text} ${this.shareData.url}`)}`;
          window.open(waUrl);
          break;
        case 'download':
          let link = document.createElement("a");
          link.href = this.video1;
          link.download = "Personal Video.mp4";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          break;
      }
    },
    async delayTime(time: number) {
       return new Promise(function(resolve){
        setTimeout(resolve, time *1000);
      });
    },

  }
});
